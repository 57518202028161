import * as React from 'react';

import Layout from '../components/common/Layout';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import { AdminLookup } from '../components/pages/AdminLookup';

const AdminLookupPage = () => {
  return (
    <Layout>
      <Page>
        <Container>
          <AdminLookup />
        </Container>
      </Page>
    </Layout>
  );
};

export default AdminLookupPage;
