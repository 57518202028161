import React, { useState } from 'react';
import {
  Paper,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import {
  AdminDataFragment,
  AdminPermission,
  AdminRole,
  AdminUpdateAdminMutation,
  AdminUpdateAdminMutationVariables,
} from '../../../generated/graphql';

const useStyles = makeStyles(theme => ({
  adminContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  adminChangeRollFormControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
}));

interface AdminProps {
  admin: AdminDataFragment;
}

export const AdminFragment = gql`
  fragment adminData on Admin {
    id
    email
    role
    permissions
  }
`;

const updateAdminMutation = gql`
  mutation adminUpdateAdmin($input: AdminUpdateAdminInput!) {
    adminUpdateAdmin(input: $input) {
      admin {
        ...adminData
      }
    }
  }
  ${AdminFragment}
`;

export const Admin = (adminInfo: AdminProps) => {
  const { admin } = adminInfo;
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newRoleChoice, setNewRoleChoise] = useState<AdminRole>(admin.role);

  const [updateAdmin, { error }] = useMutation<
    AdminUpdateAdminMutation,
    AdminUpdateAdminMutationVariables
  >(updateAdminMutation);

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    updateAdmin({
      variables: {
        input: {
          adminId: admin.id,
          role: newRoleChoice,
        },
      },
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleChangeRole = (evt: { target: { value: any } }) => {
    setNewRoleChoise(evt.target.value);
    setDialogOpen(true);
  };

  return (
    <Paper className={styles.adminContainer}>
      <h1>{admin.email}</h1>
      <h2>Role: {admin.role}</h2>
      <div>
        <FormGroup row={false}>
          {Object.entries(AdminPermission).map(([key, value]) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  disableRipple
                  checked={admin.permissions.includes(value as AdminPermission)}
                  color="primary"
                />
              }
              label={value}
            />
          ))}
        </FormGroup>
      </div>
      <div>
        <FormControl className={styles.adminChangeRollFormControl}>
          <InputLabel id="role-select-label">Change Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={admin.role}
            onChange={handleChangeRole}
          >
            {Object.entries(AdminRole).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Admin Role</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update <b>{admin.email}</b>&apos;s role to{' '}
            <b>{newRoleChoice}</b>? This admin will recieve a new set of
            permissions once you confirm your choice of role.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleDialogConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Update Admin Role
          </Button>
        </DialogActions>
      </Dialog>
      {error && <div>{error.message}</div>}
    </Paper>
  );
};
