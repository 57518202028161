import React, { FormEvent, useState } from 'react';
import {
  Paper,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  AdminQueryAdminEmailLookupArgs,
  AdminEmailLookupQuery,
} from '../../../generated/graphql';

import { AdminFragment, Admin } from './AdminDetails';

const useStyles = makeStyles(theme => ({
  searchContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  searchInput: {
    minWidth: 400,
  },
}));

const adminLookupQuery = gql`
  query adminEmailLookup($email: String!) {
    admin {
      adminEmailLookup(email: $email) {
        result {
          ...adminData
        }
      }
    }
  }
  ${AdminFragment}
`;

export const AdminLookup = () => {
  const styles = useStyles();
  const [email, setEmail] = useState('');
  const [getAdminResults, { data }] = useLazyQuery<
    AdminEmailLookupQuery,
    AdminQueryAdminEmailLookupArgs
  >(adminLookupQuery);

  const seachAdmin = (evt: FormEvent) => {
    evt.preventDefault();
    getAdminResults({ variables: { email } });
  };

  return (
    <>
      <Paper className={styles.searchContainer}>
        <h1>Admin Lookup</h1>
        <form onSubmit={seachAdmin}>
          <FormControl className={styles.searchInput}>
            <TextField
              variant="outlined"
              id="search-input"
              aria-describedby="helper-text"
              type="search"
              onChange={evt => setEmail(evt.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText id="helper-text">
              Search for admin using their EF email address
            </FormHelperText>
          </FormControl>
        </form>
      </Paper>
      {data?.admin?.adminEmailLookup?.result ? (
        <Admin admin={data.admin.adminEmailLookup.result} />
      ) : null}
    </>
  );
};
